import React from 'react';
import { graphql } from 'gatsby';
import { Grid } from 'semantic-ui-react';
import Helmet from 'react-helmet';
import { PageLayout, HeroElement, LocationsFilter } from '@fishrmn/fishrmn-components';
import Layout from '../components/Layout';
import './locations.css';


export default class extends React.Component {

  render() {
    const businessData = this.props.data.allFisherman.edges[0].node;
    const imageData = this.props.data.allFishermanImages.edges[0].node;
    const data = { ...businessData, ...imageData };

    return (
      <Layout>
        <Helmet>
          <title>{data.businessName} | Menu</title>
          <meta name="description" content="" />
        </Helmet>
        <PageLayout
          hero={ 
            <HeroElement
              header="Our Locations"
              tagline={ "" }
              images={ [ data.heroImages.map( ({url}) => url)[1] ] }
              showMap={ false }
              ctas={ [] }
              ctaInverted={ true }
              ctaColor={ "white" }
              containerColor={ "primary" }
              textColor={ "white" }
              showMultiple={ true }
              overlay={ true }
              fullWidth={ true }
              parallax={ true }
              containerAs={ "none" }
              height={ "100vh" }
              gutter={ false }
            />
          }
        >
          <Grid
            id="locationgrid"
            stackable
            className="component-section-container"
            verticalAlign={ "top" }
            textAlign={ "center" }
          >
            <Grid.Row>
              <Grid.Column width={16}>
                <LocationsFilter locations={data.locations} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </PageLayout>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allFisherman {
      edges {
        node {
          businessName
          about
          locations {
            address {
              city
              state
              street
              zip
            }
            id
            name
            phoneNumber
            primaryLocation
            timezone
          }
          reviews {
            name 
            content 
            link 
          }
          ctas {
            phone {
              link 
              text 
              internal 
            }
          }
          address {
            street 
            city 
            state 
            zip 
          }
        }
      }
    }
    allFishermanImages {
      edges {
        node {
          galleryImages {
            url 
          }
          heroImages {
            url 
          }
        }
      }
    }
  }
`;

